<template>
  <div
    class="search-indicator d-lg-none"
  >
    <!-- TODO: Ask for the UX designer how this should look and behave -->
    <div class="search-indicator-text">{{ messages.getLabelSearchResultsFor() }}: </div>
    <div class="search-indicator-query">
      <slot />
    </div>
    <!-- TODO: Adding a "reset search" button is not so straight forward. -->
    <b-button
      :title="messages.getActionResetSearch()"
      variant="outline-light"
      squared
      class="search-indicator-reset-search"
      @click="resetSearch"
    >
      X
    </b-button>
  </div>
</template>

<script>
import { messages } from '@/utils/strings'

export default {
  name: 'SearchIndicator',
  data () {
    return {
      messages
    }
  },
  methods: {
    resetSearch () {
      this.$emit('reset')
    }
  }
}
</script>

<style scoped lang="scss">

$tiny-margin: 8px;

// TODO: The search indicator is not specified in the Mobile UX design.
// This is a placeholder to show that the listed items are using search
// so that the results are not confused with the default "show all"
// view which would otherwise look identical.
.search-indicator {
  padding: 4px 0;
  margin-bottom: $tiny-margin;
  background-color: $primary;
  color: white;
  display: flex;
  flex-direction: row;
  .search-indicator-text {
    flex-grow: 0;
    margin-left: $tiny-margin;
    margin-right: $tiny-margin;
  }
  .search-indicator-query {
    flex-grow: 1;
  }
  .search-indicator-reset-search {
    margin: 0 $tiny-margin;
    padding: 0 $tiny-margin;
  }
}

</style>
